@charset "UTF-8";
/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/open-sans-v15-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Light"), local("OpenSans-Light"), url("./fonts/open-sans-v15-latin-300.eot?#iefix") format("embedded-opentype"), url("./fonts/open-sans-v15-latin-300.woff2") format("woff2"), url("./fonts/open-sans-v15-latin-300.woff") format("woff"), url("./fonts/open-sans-v15-latin-300.ttf") format("truetype"), url("./fonts/open-sans-v15-latin-300.svg#OpenSans") format("svg");
  /* Legacy iOS */ }
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/open-sans-v15-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("./fonts/open-sans-v15-latin-regular.eot?#iefix") format("embedded-opentype"), url("./fonts/open-sans-v15-latin-regular.woff2") format("woff2"), url("./fonts/open-sans-v15-latin-regular.woff") format("woff"), url("./fonts/open-sans-v15-latin-regular.ttf") format("truetype"), url("./fonts/open-sans-v15-latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/open-sans-v15-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("./fonts/open-sans-v15-latin-700.eot?#iefix") format("embedded-opentype"), url("./fonts/open-sans-v15-latin-700.woff2") format("woff2"), url("./fonts/open-sans-v15-latin-700.woff") format("woff"), url("./fonts/open-sans-v15-latin-700.ttf") format("truetype"), url("./fonts/open-sans-v15-latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

body .p-component {
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  text-decoration: none; }
body a {
  color: #007ad9;
  text-decoration: none; }
  body a:hover {
    color: #116fbf; }
  body a:active {
    color: #005b9f; }
body .p-disabled, body .p-component:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); }
body .pi {
  font-size: 1.25em; }
body .p-link {
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }

body {
  /* Validations */ }
  body .p-inputtext {
    font-size: 14px;
    color: #333333;
    background: #ffffff;
    padding: 0.429em;
    border: 1px solid #a6a6a6;
    -moz-transition: border-color 0.2s, box-shadow 0.2s;
    -o-transition: border-color 0.2s, box-shadow 0.2s;
    -webkit-transition: border-color 0.2s, box-shadow 0.2s;
    transition: border-color 0.2s, box-shadow 0.2s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-inputtext:enabled:hover {
      border-color: #212121; }
    body .p-inputtext:enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff;
      border-color: #007ad9; }
  body .p-checkbox {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px; }
    body .p-checkbox .p-checkbox-box {
      border: 1px solid #a6a6a6;
      background-color: #ffffff;
      width: 20px;
      height: 20px;
      text-align: center;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s; }
      body .p-checkbox .p-checkbox-box:not(.p-disabled):hover {
        border-color: #212121; }
      body .p-checkbox .p-checkbox-box:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff;
        border-color: #007ad9; }
      body .p-checkbox .p-checkbox-box.p-highlight {
        border-color: #007ad9;
        background-color: #007ad9;
        color: #ffffff; }
        body .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
          border-color: #005b9f;
          background-color: #005b9f;
          color: #ffffff; }
      body .p-checkbox .p-checkbox-box .p-checkbox-icon {
        overflow: hidden;
        position: relative;
        font-size: 18px; }
  body .p-checkbox-label {
    margin: 0 0 0 0.5em; }
  body .p-radiobutton {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    width: 20px;
    height: 20px; }
    body .p-radiobutton .p-radiobutton-box {
      border: 1px solid #a6a6a6;
      background-color: #ffffff;
      width: 20px;
      height: 20px;
      text-align: center;
      position: relative;
      -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%; }
      body .p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
        border-color: #212121; }
      body .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff;
        border-color: #007ad9; }
      body .p-radiobutton .p-radiobutton-box.p-highlight {
        border-color: #007ad9;
        background-color: #007ad9;
        color: #ffffff; }
        body .p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
          background-color: #ffffff; }
        body .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
          border-color: #005b9f;
          background-color: #005b9f;
          color: #ffffff; }
      body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
        background: transparent;
        width: 10px;
        height: 10px;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -5px;
        -moz-transition: background-color 0.2s;
        -o-transition: background-color 0.2s;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%; }
        body .p-radiobutton .p-radiobutton-box .p-radiobutton-icon:before {
          display: none; }
  body .p-radiobutton-label {
    margin: 0 0 0 .5em; }
  body .p-inputswitch {
    width: 3em;
    height: 1.75em; }
    body .p-inputswitch .p-inputswitch-slider {
      -moz-transition: background-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, box-shadow 0.2s;
      border-radius: 30px;
      background: #cccccc; }
      body .p-inputswitch .p-inputswitch-slider:before {
        background-color: #ffffff;
        height: 1.250em;
        width: 1.250em;
        left: .25em;
        bottom: .25em;
        border-radius: 50%;
        -webkit-transition: 0.2s;
        transition: 0.2s; }
    body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
      -webkit-transform: translateX(1.25em);
      -ms-transform: translateX(1.25em);
      transform: translateX(1.25em); }
    body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff; }
    body .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
      background-color: #b7b7b7; }
    body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
      background-color: #007ad9; }
    body .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
      background-color: #116fbf; }
  body .p-autocomplete .p-autocomplete-input {
    padding: 0.429em; }
  body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container {
    padding: 0.2145em 0.429em; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled):hover {
      border-color: #212121; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff;
      border-color: #007ad9; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token {
      margin: 0;
      padding: 0.2145em 0;
      color: #333333; }
      body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
        font-family: "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        padding: 0;
        margin: 0; }
    body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-token {
      font-size: 14px;
      padding: 0.2145em 0.429em;
      margin: 0 0.286em 0 0;
      background: #e02365;
      color: #ffffff;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
  body .p-autocomplete-panel {
    padding: 0;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .p-autocomplete-panel .p-autocomplete-items {
      padding: 0; }
      body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item {
        margin: 0;
        padding: 0.429em 0.857em;
        border: 0 none;
        color: #333333;
        background-color: transparent;
        -moz-transition: background-color 0.2s, box-shadow 0.2s;
        -o-transition: background-color 0.2s, box-shadow 0.2s;
        -webkit-transition: background-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, box-shadow 0.2s;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
        body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item:hover {
          color: #333333;
          background-color: #eaeaea; }
        body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item.p-highlight {
          color: #ffffff;
          background-color: #e02365; }
      body .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-group {
        padding: 0.429em 0.857em;
        background-color: #d8dae2;
        color: #333333; }
  body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2.357em; }
  body .p-fluid .p-autocomplete.p-autocomplete-multiple.p-autocomplete-dd .p-autocomplete-multiple-container {
    border-right: 0 none;
    width: calc(100% - 2.357em); }
  body .p-fluid .p-autocomplete.p-autocomplete-dd .p-inputtext {
    border-right: 0 none;
    width: calc(100% - 2.357em); }
  body .p-chips > ul.p-inputtext {
    padding: 0.2145em 0.429em;
    display: inline-block; }
    body .p-chips > ul.p-inputtext:not(.p-disabled):hover {
      border-color: #212121; }
    body .p-chips > ul.p-inputtext:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff;
      border-color: #007ad9; }
    body .p-chips > ul.p-inputtext .p-chips-input-token {
      padding: 0.2145em 0; }
      body .p-chips > ul.p-inputtext .p-chips-input-token input {
        font-family: "Open Sans", "Helvetica Neue", sans-serif;
        font-size: 14px;
        padding: 0;
        margin: 0;
        color: #333333; }
        body .p-chips > ul.p-inputtext .p-chips-input-token input:hover {
          border: 0 none; }
        body .p-chips > ul.p-inputtext .p-chips-input-token input:focus {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          outline: 0 none;
          border: 0 none; }
    body .p-chips > ul.p-inputtext .p-chips-token {
      font-size: 14px;
      padding: 0.2145em 0.429em;
      margin: 0 0.286em 0 0;
      background: #e02365;
      color: #ffffff;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
  body .p-dropdown {
    background: #ffffff;
    border: 1px solid #a6a6a6;
    -moz-transition: border-color 0.2s, box-shadow 0.2s;
    -o-transition: border-color 0.2s, box-shadow 0.2s;
    -webkit-transition: border-color 0.2s, box-shadow 0.2s;
    transition: border-color 0.2s, box-shadow 0.2s;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-dropdown:not(.p-disabled):hover {
      border-color: #212121; }
    body .p-dropdown:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff;
      border-color: #007ad9; }
    body .p-dropdown .p-dropdown-label {
      padding-right: 2em; }
      body .p-dropdown .p-dropdown-label:focus {
        outline: 0 none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none; }
    body .p-dropdown .p-dropdown-trigger {
      background-color: #ffffff;
      width: 2em;
      line-height: 2em;
      text-align: center;
      padding: 0;
      color: #848484;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
    body .p-dropdown .p-dropdown-clear-icon {
      color: #848484; }
  body .p-dropdown-panel {
    padding: 0;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .p-dropdown-panel .p-dropdown-filter-container {
      padding: 0.429em 0.857em 0.429em 0.857em;
      border-bottom: 1px solid #eaeaea;
      color: #333333;
      background-color: #ffffff;
      margin: 0; }
      body .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter {
        width: 100%;
        padding-right: 2em; }
      body .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon {
        top: 50%;
        margin-top: -.5em;
        right: 1.357em;
        color: #007ad9; }
    body .p-dropdown-panel .p-dropdown-items {
      padding: 0; }
      body .p-dropdown-panel .p-dropdown-items .p-dropdown-item, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
        margin: 0;
        padding: 0.429em 0.857em;
        border: 0 none;
        color: #333333;
        background-color: transparent;
        -moz-transition: background-color 0.2s, box-shadow 0.2s;
        -o-transition: background-color 0.2s, box-shadow 0.2s;
        -webkit-transition: background-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, box-shadow 0.2s;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
        body .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group.p-highlight {
          color: #ffffff;
          background-color: #e02365; }
        body .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group:not(.p-highlight):not(.p-disabled):hover {
          color: #333333;
          background-color: #eaeaea; }
  body .p-multiselect {
    background: #ffffff;
    border: 1px solid #a6a6a6;
    -moz-transition: border-color 0.2s, box-shadow 0.2s;
    -o-transition: border-color 0.2s, box-shadow 0.2s;
    -webkit-transition: border-color 0.2s, box-shadow 0.2s;
    transition: border-color 0.2s, box-shadow 0.2s;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-multiselect:not(.p-disabled):hover {
      border-color: #212121; }
    body .p-multiselect:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff;
      border-color: #007ad9; }
    body .p-multiselect .p-multiselect-label {
      padding: 0.429em;
      padding-right: 2em;
      font-weight: 400;
      color: #333333; }
    body .p-multiselect .p-multiselect-trigger {
      background-color: #ffffff;
      width: 2em;
      line-height: 2em;
      text-align: center;
      padding: 0;
      color: #848484;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
  body .p-multiselect-panel {
    padding: 0;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .p-multiselect-panel .p-multiselect-header {
      padding: 0.429em 0.857em 0.429em 0.857em;
      border-bottom: 1px solid #eaeaea;
      color: #333333;
      background-color: #ffffff;
      margin: 0; }
      body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
        float: none;
        width: 70%;
        display: inline-block;
        vertical-align: middle;
        margin-left: 0; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
          padding: 0.429em;
          padding-right: 2em; }
        body .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
          color: #007ad9;
          top: 50%;
          margin-top: -.5em;
          right: .5em;
          left: auto; }
      body .p-multiselect-panel .p-multiselect-header .p-checkbox {
        margin-right: 0.5em;
        float: none;
        vertical-align: middle; }
      body .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
        color: #848484;
        top: 50%;
        margin-top: -.5em;
        -moz-transition: box-shadow 0.2s;
        -o-transition: box-shadow 0.2s;
        -webkit-transition: box-shadow 0.2s;
        transition: box-shadow 0.2s; }
    body .p-multiselect-panel .p-multiselect-items {
      padding: 0; }
      body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        margin: 0;
        padding: 0.429em 0.857em;
        border: 0 none;
        color: #333333;
        background-color: transparent;
        -moz-transition: background-color 0.2s, box-shadow 0.2s;
        -o-transition: background-color 0.2s, box-shadow 0.2s;
        -webkit-transition: background-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, box-shadow 0.2s;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
        body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
          color: #333333;
          background-color: #eaeaea; }
        body .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
          outline: 0 none;
          outline-offset: 0;
          -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
          -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
          box-shadow: inset 0 0 0 0.2em #8dcdff; }
        body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
          color: #ffffff;
          background-color: #e02365; }
        body .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
          position: static;
          display: inline-block;
          vertical-align: middle;
          margin: 0 0.5em 0 0; }
        body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
          display: inline-block;
          vertical-align: middle; }
  body .p-listbox {
    padding: 0;
    min-width: 12em;
    background: #ffffff;
    border: 1px solid #a6a6a6; }
    body .p-listbox .p-checkbox {
      margin: 0 0.5em 0 0; }
    body .p-listbox .p-listbox-header {
      padding: 0.429em 0.857em 0.429em 0.857em;
      border-bottom: 1px solid #eaeaea;
      color: #333333;
      background-color: #ffffff;
      margin: 0;
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px; }
      body .p-listbox .p-listbox-header .p-listbox-filter-container input {
        padding: 0.429em;
        padding-right: 2em; }
      body .p-listbox .p-listbox-header .p-listbox-filter-container .p-listbox-filter-icon {
        top: 50%;
        left: auto;
        margin-top: -.5em;
        right: .5em;
        color: #007ad9; }
    body .p-listbox .p-listbox-list {
      background-color: #ffffff; }
      body .p-listbox .p-listbox-list .p-listbox-item {
        margin: 0;
        padding: 0.429em 0.857em;
        border: 0 none;
        color: #333333;
        background-color: transparent;
        -moz-transition: background-color 0.2s, box-shadow 0.2s;
        -o-transition: background-color 0.2s, box-shadow 0.2s;
        -webkit-transition: background-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, box-shadow 0.2s;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        border-radius: 0; }
        body .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
          color: #ffffff;
          background-color: #e02365; }
        body .p-listbox .p-listbox-list .p-listbox-item:focus {
          outline: 0 none;
          outline-offset: 0;
          -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
          -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
          box-shadow: inset 0 0 0 0.2em #8dcdff; }
        body .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
          position: static;
          display: inline-block;
          vertical-align: middle;
          margin: 0 0.5em 0 0; }
        body .p-listbox .p-listbox-list .p-listbox-item label {
          display: inline-block;
          vertical-align: middle; }
    body .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
      color: #333333;
      background-color: #eaeaea; }
    body .p-listbox.p-disabled .p-checkbox-box:not(.p-disabled):not(.p-highlight):hover {
      border: 1px solid #a6a6a6; }
    body .p-listbox .p-listbox-footer {
      padding: 0.429em 0.857em 0.429em 0.857em;
      border-top: 1px solid #eaeaea;
      color: #333333;
      background-color: #ffffff; }
  body .p-editor-container .p-editor-toolbar {
    border: 1px solid #007ad9;
    background-color: #007ad9; }
  body .p-editor-container .p-editor-content {
    border: 1px solid #c8c8c8; }
    body .p-editor-container .p-editor-content .ql-editor {
      background-color: #ffffff;
      color: #333333; }
  body .p-editor-container .ql-picker.ql-expanded .ql-picker-label {
    color: #ffffff; }
  body .p-editor-container .ql-stroke {
    stroke: #ffffff; }
  body .p-editor-container .ql-picker-label {
    color: #ffffff; }
  body .p-editor-container .ql-snow.ql-toolbar button:hover,
  body .p-editor-container .ql-snow .ql-toolbar button:hover,
  body .p-editor-container .ql-snow.ql-toolbar button.ql-active,
  body .p-editor-container .ql-snow .ql-toolbar button.ql-active,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #e02365; }
  body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
  body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke,
  body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #e02365; }
  body .p-rating .p-rating-icon {
    font-size: 20px;
    text-align: center;
    display: inline-block;
    color: #333333;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: color 0.2s, box-shadow 0.2s;
    -o-transition: color 0.2s, box-shadow 0.2s;
    -webkit-transition: color 0.2s, box-shadow 0.2s;
    transition: color 0.2s, box-shadow 0.2s; }
    body .p-rating .p-rating-icon.p-rating-cancel {
      color: #e4018d; }
    body .p-rating .p-rating-icon:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-rating:not(.p-disabled):not(.p-rating-readonly) .p-rating-icon:hover {
    color: #007ad9; }
  body .p-rating:not(.p-disabled):not(.p-rating-readonly) .p-rating-icon.p-rating-cancel:hover {
    color: #b5019f; }
  body .p-spinner .p-spinner-input {
    padding-right: 2.429em; }
  body .p-spinner .p-spinner-button {
    width: 2em; }
    body .p-spinner .p-spinner-button .p-spinner-button-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -.5em;
      margin-left: -.5em;
      width: 1em; }
    body .p-spinner .p-spinner-button.p-spinner-button {
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-spinner .p-spinner-button.p-spinner-button.p-spinner-button-up {
        -moz-border-radius-topright: 3px;
        -webkit-border-top-right-radius: 3px;
        border-top-right-radius: 3px; }
      body .p-spinner .p-spinner-button.p-spinner-button.p-spinner-button-down {
        -moz-border-radius-bottomright: 3px;
        -webkit-border-bottom-right-radius: 3px;
        border-bottom-right-radius: 3px; }
      body .p-spinner .p-spinner-button.p-spinner-button:focus {
        z-index: 1; }
  body .p-fluid .p-spinner .p-spinner-input {
    padding-right: 2.429em; }
  body .p-fluid .p-spinner .p-spinner-button {
    width: 2em; }
    body .p-fluid .p-spinner .p-spinner-button .p-spinner-button-icon {
      left: 50%; }
  body .p-slider {
    background-color: #c8c8c8;
    border: 0 none; }
    body .p-slider.p-slider-horizontal {
      height: 0.286em; }
      body .p-slider.p-slider-horizontal .p-slider-handle {
        top: 50%;
        margin-top: -0.5715em; }
    body .p-slider.p-slider-vertical {
      width: 0.286em; }
      body .p-slider.p-slider-vertical .p-slider-handle {
        left: 50%;
        margin-left: -0.5715em; }
    body .p-slider .p-slider-handle {
      height: 1.143em;
      width: 1.143em;
      background-color: #ffffff;
      border: 2px solid #666666;
      -moz-border-radius: 100%;
      -webkit-border-radius: 100%;
      border-radius: 100%;
      -moz-transition: box-shadow 0.2s;
      -o-transition: box-shadow 0.2s;
      -webkit-transition: box-shadow 0.2s;
      transition: box-shadow 0.2s; }
      body .p-slider .p-slider-handle:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff; }
    body .p-slider .p-slider-range {
      background-color: #007ad9; }
    body .p-slider:not(.p-disabled) .p-slider-handle:hover {
      background-color: 2px solid #666666;
      border: 2px solid #007ad9; }
  body .p-datepicker {
    padding: 0.857em;
    min-width: 20em;
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #a6a6a6;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-datepicker:not(.p-datepicker-inline) {
      border: 1px solid #c8c8c8;
      -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-prev:hover,
    body .p-datepicker:not(.p-disabled) .p-datepicker-header .p-datepicker-next:hover {
      color: #007ad9; }
    body .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
      background-color: #eaeaea; }
    body .p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):hover {
      background-color: #eaeaea; }
    body .p-datepicker .p-datepicker-header {
      padding: 0.429em 0.857em 0.429em 0.857em;
      background-color: #ffffff;
      color: #333333;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-datepicker .p-datepicker-header .p-datepicker-prev,
      body .p-datepicker .p-datepicker-header .p-datepicker-next {
        cursor: pointer;
        top: 0;
        color: #a6a6a6;
        -moz-transition: color 0.2s, box-shadow 0.2s;
        -o-transition: color 0.2s, box-shadow 0.2s;
        -webkit-transition: color 0.2s, box-shadow 0.2s;
        transition: color 0.2s, box-shadow 0.2s; }
      body .p-datepicker .p-datepicker-header .p-datepicker-title {
        margin: 0;
        padding: 0;
        line-height: 1; }
        body .p-datepicker .p-datepicker-header .p-datepicker-title select {
          margin-top: -.35em;
          margin-bottom: 0; }
    body .p-datepicker table {
      font-size: 14px;
      margin: 0.857em 0 0 0; }
      body .p-datepicker table th {
        padding: 0.5em; }
      body .p-datepicker table td {
        padding: 0.5em; }
        body .p-datepicker table td > span {
          display: block;
          text-align: center;
          color: #333333;
          cursor: pointer;
          padding: 0.5em;
          -moz-border-radius: 3px;
          -webkit-border-radius: 3px;
          border-radius: 3px; }
          body .p-datepicker table td > span.p-highlight {
            color: #ffffff;
            background-color: #007ad9; }
        body .p-datepicker table td.p-datepicker-today > span {
          background-color: #d0d0d0;
          color: #333333; }
          body .p-datepicker table td.p-datepicker-today > span.p-highlight {
            color: #ffffff;
            background-color: #007ad9; }
    body .p-datepicker .p-datepicker-buttonbar {
      border-top: 1px solid #d8dae2; }
    body .p-datepicker .p-timepicker {
      border: 0 none;
      border-top: 1px solid #d8dae2;
      padding: 0.857em; }
      body .p-datepicker .p-timepicker button {
        color: #a6a6a6;
        font-size: 1.286em;
        -moz-transition: color 0.2s, box-shadow 0.2s;
        -o-transition: color 0.2s, box-shadow 0.2s;
        -webkit-transition: color 0.2s, box-shadow 0.2s;
        transition: color 0.2s, box-shadow 0.2s; }
        body .p-datepicker .p-timepicker button:hover {
          color: #007ad9; }
      body .p-datepicker .p-timepicker span {
        font-size: 1.286em; }
    body .p-datepicker .p-monthpicker .p-monthpicker-month {
      color: #333333; }
      body .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
        color: #ffffff;
        background-color: #007ad9; }
    body .p-datepicker.p-datepicker-timeonly {
      padding: 0; }
      body .p-datepicker.p-datepicker-timeonly .p-timepicker {
        border-top: 0 none; }
    body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
      border-right: 1px solid #d8dae2;
      padding-right: 0.857em;
      padding-left: 0.857em;
      padding-top: 0;
      padding-bottom: 0; }
      body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
        padding-left: 0; }
      body .p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
        padding-right: 0;
        border-right: 0 none; }
  body .p-calendar.p-calendar-w-btn .p-inputtext {
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 none; }
    body .p-calendar.p-calendar-w-btn .p-inputtext:enabled:hover:not(.p-error), body .p-calendar.p-calendar-w-btn .p-inputtext:enabled:focus:not(.p-error) {
      border-right: 0 none; }
  body .p-calendar.p-calendar-w-btn .p-datepicker-trigger.p-button {
    width: 2.357em;
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0; }
  body .p-fluid .p-calendar.p-calendar-w-btn .p-inputtext {
    width: calc(100% - 2.357em); }
  body .p-fileupload .p-fileupload-buttonbar {
    background-color: #007ad9;
    padding: 0.571em 1em;
    border: 1px solid #007ad9;
    color: #ffffff;
    border-bottom: 0 none;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px; }
    body .p-fileupload .p-fileupload-buttonbar .p-button {
      margin-right: 8px; }
      body .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-fileupload .p-fileupload-content {
    background-color: #ffffff;
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    color: #333333;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  body .p-fileupload .p-progressbar {
    top: 0; }
  body .p-fileupload-choose:not(.p-disabled):hover {
    background-color: #116fbf;
    color: #ffffff;
    border-color: #116fbf; }
  body .p-fileupload-choose:not(.p-disabled):active {
    background-color: #005b9f;
    color: #ffffff;
    border-color: #005b9f; }
  body .p-password-panel {
    padding: 12px;
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
  body .p-inputgroup .p-inputgroup-addon {
    border-color: #a6a6a6;
    background-color: #eaeaea;
    color: #848484;
    padding: 0.429em;
    min-width: 2em; }
    body .p-inputgroup .p-inputgroup-addon:first-child {
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-bottomleft: 3px;
      -webkit-border-bottom-left-radius: 3px;
      border-bottom-left-radius: 3px; }
    body .p-inputgroup .p-inputgroup-addon:last-child {
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-border-radius-bottomright: 3px;
      -webkit-border-bottom-right-radius: 3px;
      border-bottom-right-radius: 3px; }
    body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-checkbox {
      position: relative; }
      body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-checkbox .p-checkbox {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -10px;
        margin-top: -10px; }
    body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-radiobutton {
      position: relative; }
      body .p-inputgroup .p-inputgroup-addon.p-inputgroup-addon-radiobutton .p-radiobutton {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -10px;
        margin-top: -10px; }
  body .p-inputgroup .p-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  body .p-inputgroup .p-button:last-child {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  body .p-inputgroup .p-inputtext:focus {
    z-index: 1; }
  body .p-fluid .p-inputgroup .p-button {
    width: auto; }
    body .p-fluid .p-inputgroup .p-button.p-button-icon-only {
      width: 2.357em; }
  body ::-webkit-input-placeholder {
    color: #666666; }
  body :-moz-placeholder {
    color: #666666; }
  body ::-moz-placeholder {
    color: #666666; }
  body :-ms-input-placeholder {
    color: #666666; }
  body .p-inputtext.p-error,
  body .p-dropdown.p-error,
  body .p-autocomplete.p-error > .p-inputtext,
  body .p-calendar.p-error > .p-inputtext,
  body .p-chips.p-error > .p-inputtext,
  body .p-checkbox.p-error > .p-checkbox-box,
  body .p-radiobutton.p-error > .p-radiobutton-box,
  body .p-inputswitch.p-error,
  body .p-listbox.p-error,
  body .p-multiselect.p-error,
  body .p-spinner.p-error > .p-inputtext,
  body .p-selectbutton.p-error > .p-button,
  body .p-togglebutton.p-error > .p-button {
    border: 1px solid #a80000; }

body .p-button {
  margin: 0;
  color: #ffffff;
  background-color: #007ad9;
  border: 1px solid #007ad9;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-button:enabled:hover {
    background-color: #116fbf;
    color: #ffffff;
    border-color: #116fbf; }
  body .p-button:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-button:enabled:active {
    background-color: #005b9f;
    color: #ffffff;
    border-color: #005b9f; }
  body .p-button.p-button-text-only .p-button-text {
    padding: 0.429em 1em; }
  body .p-button.p-button-text-icon-left .p-button-text {
    padding: 0.429em 1em 0.429em 2.25em; }
  body .p-button.p-button-text-icon-right .p-button-text {
    padding: 0.429em 2.25em 0.429em 1em; }
  body .p-button.p-button-icon-only {
    width: 2.357em; }
    body .p-button.p-button-icon-only .p-button-text {
      padding: 0.429em; }
  body .p-button.p-button-raised {
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15); }
  body .p-button.p-button-rounded {
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border-radius: 15px; }
body .p-fluid .p-button-icon-only {
  width: 2.357em; }
body .p-togglebutton {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: #333333;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s; }
  body .p-togglebutton .p-button-icon-left {
    color: #666666; }
  body .p-togglebutton:not(.p-disabled):not(.p-highlight):hover {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: #333333; }
    body .p-togglebutton:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
      color: #212121; }
  body .p-togglebutton.p-focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-togglebutton.p-highlight {
    background-color: #007ad9;
    border-color: #007ad9;
    color: #ffffff; }
    body .p-togglebutton.p-highlight .p-button-icon-left {
      color: #ffffff; }
    body .p-togglebutton.p-highlight:hover {
      background-color: #116fbf;
      border-color: #116fbf;
      color: #ffffff; }
      body .p-togglebutton.p-highlight:hover .p-button-icon-left {
        color: #ffffff; }
body .p-selectbutton .p-button {
  background-color: #dadada;
  border: 1px solid #dadada;
  color: #333333;
  outline: 0 none;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .p-selectbutton .p-button .p-button-icon-left {
    color: #666666; }
  body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    color: #333333; }
    body .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left {
      color: #212121; }
  body .p-selectbutton .p-button.p-focus, body .p-selectbutton .p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff;
    z-index: 1; }
  body .p-selectbutton .p-button.p-highlight {
    background-color: #007ad9;
    border-color: #007ad9;
    color: #ffffff; }
    body .p-selectbutton .p-button.p-highlight .p-button-icon-left {
      color: #ffffff; }
    body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover {
      background-color: #116fbf;
      border-color: #116fbf;
      color: #ffffff; }
      body .p-selectbutton .p-button.p-highlight:not(.p-disabled):hover .p-button-icon-left {
        color: #ffffff; }
  body .p-selectbutton .p-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  body .p-selectbutton .p-button:last-child {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
body .p-splitbutton.p-buttonset .p-button {
  border: 1px solid transparent; }
  body .p-splitbutton.p-buttonset .p-button:first-child {
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0; }
  body .p-splitbutton.p-buttonset .p-button.p-splitbutton-menubutton {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0; }
  body .p-splitbutton.p-buttonset .p-button:focus {
    z-index: 1; }
body .p-splitbutton.p-buttonset .p-menu {
  min-width: 100%; }
body .p-splitbutton.p-buttonset .p-splitbutton-menubutton .p-button-icon-left:before {
  content: ""; }
body .p-button.p-button-secondary, body .p-buttonset.p-button-secondary > .p-button {
  color: #333333;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4; }
  body .p-button.p-button-secondary:enabled:hover, body .p-buttonset.p-button-secondary > .p-button:enabled:hover {
    background-color: #c8c8c8;
    color: #333333;
    border-color: #c8c8c8; }
  body .p-button.p-button-secondary:enabled:focus, body .p-buttonset.p-button-secondary > .p-button:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-button.p-button-secondary:enabled:active, body .p-buttonset.p-button-secondary > .p-button:enabled:active {
    background-color: #a0a0a0;
    color: #333333;
    border-color: #a0a0a0; }
body .p-button.p-button-info, body .p-buttonset.p-button-info > .p-button {
  color: #ffffff;
  background-color: #007ad9;
  border: 1px solid #007ad9; }
  body .p-button.p-button-info:enabled:hover, body .p-buttonset.p-button-info > .p-button:enabled:hover {
    background-color: #116fbf;
    color: #ffffff;
    border-color: #116fbf; }
  body .p-button.p-button-info:enabled:focus, body .p-buttonset.p-button-info > .p-button:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-button.p-button-info:enabled:active, body .p-buttonset.p-button-info > .p-button:enabled:active {
    background-color: #005b9f;
    color: #ffffff;
    border-color: #005b9f; }
body .p-button.p-button-success, body .p-buttonset.p-button-success > .p-button {
  color: #ffffff;
  background-color: #34A835;
  border: 1px solid #34A835; }
  body .p-button.p-button-success:enabled:hover, body .p-buttonset.p-button-success > .p-button:enabled:hover {
    background-color: #107D11;
    color: #ffffff;
    border-color: #107D11; }
  body .p-button.p-button-success:enabled:focus, body .p-buttonset.p-button-success > .p-button:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #aae5aa;
    -moz-box-shadow: 0 0 0 0.2em #aae5aa;
    box-shadow: 0 0 0 0.2em #aae5aa; }
  body .p-button.p-button-success:enabled:active, body .p-buttonset.p-button-success > .p-button:enabled:active {
    background-color: #0C6B0D;
    color: #ffffff;
    border-color: #0C6B0D; }
body .p-button.p-button-warning, body .p-buttonset.p-button-warning > .p-button {
  color: #333333;
  background-color: #ffba01;
  border: 1px solid #ffba01; }
  body .p-button.p-button-warning:enabled:hover, body .p-buttonset.p-button-warning > .p-button:enabled:hover {
    background-color: #ED990B;
    color: #333333;
    border-color: #ED990B; }
  body .p-button.p-button-warning:enabled:focus, body .p-buttonset.p-button-warning > .p-button:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #ffeab4;
    -moz-box-shadow: 0 0 0 0.2em #ffeab4;
    box-shadow: 0 0 0 0.2em #ffeab4; }
  body .p-button.p-button-warning:enabled:active, body .p-buttonset.p-button-warning > .p-button:enabled:active {
    background-color: #D38B10;
    color: #333333;
    border-color: #D38B10; }
body .p-button.p-button-danger, body .p-buttonset.p-button-danger > .p-button {
  color: #ffffff;
  background-color: #e91224;
  border: 1px solid #e91224; }
  body .p-button.p-button-danger:enabled:hover, body .p-buttonset.p-button-danger > .p-button:enabled:hover {
    background-color: #c01120;
    color: #ffffff;
    border-color: #c01120; }
  body .p-button.p-button-danger:enabled:focus, body .p-buttonset.p-button-danger > .p-button:enabled:focus {
    -webkit-box-shadow: 0 0 0 0.2em #f9b4ba;
    -moz-box-shadow: 0 0 0 0.2em #f9b4ba;
    box-shadow: 0 0 0 0.2em #f9b4ba; }
  body .p-button.p-button-danger:enabled:active, body .p-buttonset.p-button-danger > .p-button:enabled:active {
    background-color: #a90000;
    color: #ffffff;
    border-color: #a90000; }

body .p-panel {
  padding: 0;
  border: 0 none; }
  body .p-panel .p-panel-titlebar {
    border: 1px solid #007ad9;
    padding: 0.571em 1em;
    background-color: #007ad9;
    color: #ffffff;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0; }
    body .p-panel .p-panel-titlebar .p-panel-title {
      vertical-align: middle;
      font-weight: 700; }
    body .p-panel .p-panel-titlebar .p-panel-titlebar-icon {
      margin: 0.143em 0 0 0;
      position: relative;
      font-size: 14px;
      color: #ffffff;
      border: 1px solid transparent;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      -moz-transition: color 0.2s, box-shadow 0.2s;
      -o-transition: color 0.2s, box-shadow 0.2s;
      -webkit-transition: color 0.2s, box-shadow 0.2s;
      transition: color 0.2s, box-shadow 0.2s; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:hover {
        color: #5ab7ff; }
      body .p-panel .p-panel-titlebar .p-panel-titlebar-icon:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-panel .p-panel-content {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    color: #333333;
    border-top: 0 none;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  body .p-panel .p-panel-footer {
    padding: 0.571em 1em;
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    color: #333333;
    border-top: 0 none;
    margin: 0; }
body .p-fieldset {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-fieldset .p-fieldset-legend a {
    padding: 0.571em 1em;
    border: 1px solid #007ad9;
    color: #ffffff;
    background-color: #007ad9;
    font-weight: 700;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s; }
    body .p-fieldset .p-fieldset-legend a .p-fieldset-toggler {
      float: none;
      display: inline-block;
      vertical-align: middle;
      margin-right: .5em;
      color: #ffffff; }
    body .p-fieldset .p-fieldset-legend a .p-fieldset-legend-text {
      padding: 0; }
    body .p-fieldset .p-fieldset-legend a:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover {
    background-color: #27a0ff;
    border: 1px solid #27a0ff;
    color: #ffffff; }
    body .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:hover .p-fieldset-toggler {
      color: #ffffff; }
  body .p-fieldset .p-fieldset-content {
    padding: 0; }
body .p-accordion .p-accordion-tab {
  margin-bottom: 2px; }
body .p-accordion .p-accordion-header a {
  padding: 0.571em 1em;
  border: 1px solid #007ad9;
  color: #ffffff;
  background-color: #007ad9;
  color: #ffffff;
  font-weight: 700;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s; }
  body .p-accordion .p-accordion-header a .p-accordion-toggle-icon {
    color: #ffffff; }
    body .p-accordion .p-accordion-header a .p-accordion-toggle-icon.pi-caret-right:before {
      content: ""; }
    body .p-accordion .p-accordion-header a .p-accordion-toggle-icon.pi-caret-down:before {
      content: ""; }
body .p-accordion .p-accordion-header:not(.p-disabled) a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff; }
body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover a {
  background-color: #27a0ff;
  border: 1px solid #27a0ff;
  color: #ffffff; }
  body .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover a .p-accordion-toggle-icon {
    color: #ffffff; }
body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight {
  margin-bottom: 0; }
  body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a {
    background-color: #e02365;
    border: 1px solid #e02365;
    color: #ffffff;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0; }
    body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight a .p-accordion-toggle-icon {
      color: #ffffff; }
  body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a {
    border: 1px solid #e96694;
    background-color: #e96694;
    color: #ffffff; }
    body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a .p-accordion-toggle-icon {
      color: #ffffff; }
body .p-accordion .p-accordion-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  border-top: 0;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
body .p-tabview.p-tabview-top, body .p-tabview.p-tabview-bottom, body .p-tabview.p-tabview-left, body .p-tabview.p-tabview-right {
  border: 0 none; }
  body .p-tabview.p-tabview-top .p-tabview-nav, body .p-tabview.p-tabview-bottom .p-tabview-nav, body .p-tabview.p-tabview-left .p-tabview-nav, body .p-tabview.p-tabview-right .p-tabview-nav {
    padding: 0;
    background: transparent;
    border: 0 none; }
    body .p-tabview.p-tabview-top .p-tabview-nav li a, body .p-tabview.p-tabview-bottom .p-tabview-nav li a, body .p-tabview.p-tabview-left .p-tabview-nav li a, body .p-tabview.p-tabview-right .p-tabview-nav li a {
      border: 1px solid #007ad9;
      background-color: #007ad9;
      float: none;
      display: inline-block;
      color: #ffffff;
      padding: 0.571em 1em;
      font-weight: 700;
      -moz-transition: background-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, box-shadow 0.2s; }
      body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-left-icon, body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-left-icon {
        margin-right: 0.5em; }
      body .p-tabview.p-tabview-top .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-left .p-tabview-nav li a .p-tabview-right-icon, body .p-tabview.p-tabview-right .p-tabview-nav li a .p-tabview-right-icon {
        margin-left: 0.5em; }
      body .p-tabview.p-tabview-top .p-tabview-nav li a:not(.p-disabled):focus, body .p-tabview.p-tabview-bottom .p-tabview-nav li a:not(.p-disabled):focus, body .p-tabview.p-tabview-left .p-tabview-nav li a:not(.p-disabled):focus, body .p-tabview.p-tabview-right .p-tabview-nav li a:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff; }
    body .p-tabview.p-tabview-top .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li .p-tabview-close {
      color: #ffffff;
      margin: 0 0.5em 0 0;
      vertical-align: middle; }
    body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a, body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a, body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a, body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a {
      background-color: #27a0ff;
      border: 1px solid #27a0ff;
      color: #ffffff; }
    body .p-tabview.p-tabview-top .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-close {
      color: #ffffff; }
    body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight a {
      background-color: #e02365;
      border: 1px solid #e02365;
      color: #ffffff; }
    body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight .p-tabview-close, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight .p-tabview-close, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight .p-tabview-close, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight .p-tabview-close {
      color: #ffffff; }
    body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a {
      border: 1px solid #e96694;
      background-color: #e96694;
      color: #ffffff; }
      body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-top .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-left .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a .p-tabview-left-icon, body .p-tabview.p-tabview-right .p-tabview-nav li.p-highlight:hover a .p-tabview-right-icon {
        color: #ffffff; }
    body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-selected a, body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-selected a, body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-selected a, body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-selected a {
      cursor: pointer; }
body .p-tabview.p-tabview-top .p-tabview-nav {
  margin-bottom: -1px; }
  body .p-tabview.p-tabview-top .p-tabview-nav li a {
    margin-right: 2px;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px; }
body .p-tabview.p-tabview-bottom .p-tabview-nav {
  margin-top: -1px; }
  body .p-tabview.p-tabview-bottom .p-tabview-nav li a {
    margin-right: 2px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
body .p-tabview.p-tabview-left .p-tabview-nav {
  margin-right: -px; }
  body .p-tabview.p-tabview-left .p-tabview-nav li a {
    margin-bottom: 2px;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
body .p-tabview.p-tabview-right .p-tabview-nav {
  margin-right: -1px; }
  body .p-tabview.p-tabview-right .p-tabview-nav li a {
    margin-bottom: 2px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
body .p-tabview .p-tabview-panels {
  background-color: #ffffff;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  color: #333333;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
  body .p-tabview .p-tabview-panels .p-tabview-panel {
    padding: 0; }
body .p-toolbar {
  background-color: #007ad9;
  border: 1px solid #007ad9;
  padding: 0.571em 1em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-toolbar button {
    vertical-align: middle; }
  body .p-toolbar .p-toolbar-separator {
    vertical-align: middle;
    color: #ffffff;
    margin: 0 0.5em; }
body .p-card {
  background-color: #ffffff;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12); }

body .p-paginator {
  background-color: #f4f4f4;
  border: 1px solid #c8c8c8;
  padding: 0; }
  body .p-paginator .p-paginator-first,
  body .p-paginator .p-paginator-prev,
  body .p-paginator .p-paginator-next,
  body .p-paginator .p-paginator-last {
    color: #848484;
    height: 2.286em;
    min-width: 2.286em;
    border: 0 none;
    line-height: 2.286em;
    padding: 0;
    margin: 0;
    vertical-align: top;
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    body .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    body .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    body .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
      background-color: #e0e0e0;
      color: #333333; }
  body .p-paginator .p-dropdown {
    border: 0 none; }
    body .p-paginator .p-dropdown .p-dropdown-trigger, body .p-paginator .p-dropdown .p-dropdown-label {
      color: #848484; }
    body .p-paginator .p-dropdown:hover .p-dropdown-trigger, body .p-paginator .p-dropdown:hover .p-dropdown-label {
      color: #333333; }
  body .p-paginator .p-paginator-first:before {
    position: relative;
    top: 1px; }
  body .p-paginator .p-paginator-prev:before {
    position: relative; }
  body .p-paginator .p-paginator-next:before {
    position: relative;
    top: 1px; }
  body .p-paginator .p-paginator-last:before {
    position: relative;
    top: 1px; }
  body .p-paginator .p-paginator-current {
    vertical-align: top;
    display: inline-block;
    height: 2.286em;
    min-width: 2.286em;
    border: 0 none;
    line-height: 2.286em; }
  body .p-paginator .p-paginator-pages {
    vertical-align: top;
    display: inline-block;
    padding: 0; }
    body .p-paginator .p-paginator-pages .p-paginator-page {
      color: #848484;
      height: 2.286em;
      min-width: 2.286em;
      border: 0 none;
      line-height: 2.286em;
      padding: 0;
      margin: 0;
      vertical-align: top;
      -moz-transition: background-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, box-shadow 0.2s;
      -moz-border-radius: 0;
      -webkit-border-radius: 0;
      border-radius: 0; }
      body .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background-color: #e02365;
        color: #ffffff; }
      body .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
        background-color: #e0e0e0;
        color: #333333; }
  body .p-paginator .p-dropdown {
    margin-left: 0.5em;
    height: 2.286em;
    min-width: auto; }
body .p-datatable .p-datatable-header,
body .p-datatable .p-datatable-footer {
  background-color: #007ad9;
  color: #ffffff;
  border: 1px solid #007ad9;
  padding: 0.571em 1em;
  text-align: center; }
body .p-datatable .p-datatable-header {
  border-bottom: 0 none;
  font-weight: 700;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
body .p-datatable .p-datatable-footer {
  border-top: 0 none;
  font-weight: 700;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
body .p-datatable .p-datatable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4;
  text-align: center; }
body .p-datatable .p-datatable-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4;
  text-align: center; }
body .p-datatable .p-sortable-column {
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s; }
  body .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #848484; }
  body .p-datatable .p-sortable-column:not(.p-highlight):hover {
    background-color: #e0e0e0;
    color: #333333; }
    body .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: #333333; }
  body .p-datatable .p-sortable-column:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
    box-shadow: inset 0 0 0 0.2em #8dcdff; }
  body .p-datatable .p-sortable-column.p-highlight {
    background-color: #e02365;
    color: #ffffff; }
    body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: #ffffff; }
body .p-datatable .p-editable-column input {
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif; }
body .p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #333333;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s; }
  body .p-datatable .p-datatable-tbody > tr > td {
    background: inherit;
    border: 1px solid #c8c8c8;
    padding: 0.429em 0.857em; }
    body .p-datatable .p-datatable-tbody > tr > td .p-row-toggler {
      color: #848484;
      -moz-transition: color 0.2s, box-shadow 0.2s;
      -o-transition: color 0.2s, box-shadow 0.2s;
      -webkit-transition: color 0.2s, box-shadow 0.2s;
      transition: color 0.2s, box-shadow 0.2s; }
      body .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:hover {
        color: #333333; }
  body .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: #e02365;
    color: #ffffff; }
    body .p-datatable .p-datatable-tbody > tr.p-highlight .p-row-toggler {
      color: #ffffff; }
      body .p-datatable .p-datatable-tbody > tr.p-highlight .p-row-toggler:hover {
        color: #ffffff; }
  body .p-datatable .p-datatable-tbody > tr.p-highlight-contextmenu {
    background-color: #f093b3;
    color: #ffffff; }
  body .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #e02365; }
  body .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #e02365; }
body .p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f9f9f9; }
  body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
    background-color: #e02365;
    color: #ffffff; }
    body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
      color: #ffffff; }
      body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
        color: #ffffff; }
  body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight-contextmenu {
    background-color: #f093b3;
    color: #ffffff; }
    body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight-contextmenu .p-row-toggler {
      color: #ffffff; }
      body .p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight-contextmenu .p-row-toggler:hover {
        color: #ffffff; }
body .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-highlight-contextmenu):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: #333333; }
body .p-datatable .p-column-resizer-helper {
  background-color: #007ad9; }
body .p-datatable .p-datatable-scrollable-header,
body .p-datatable .p-datatable-scrollable-footer {
  background-color: #007ad9; }
@media screen and (max-width: 40em) {
  body .p-datatable.p-datatable-responsive .p-paginator-top {
    border-bottom: 1px solid #c8c8c8; }
  body .p-datatable.p-datatable-responsive .p-paginator-bottom {
    border-top: 1px solid #c8c8c8; }
  body .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
    border: 0 none; } }
body .p-datagrid .p-datagrid-header {
  background-color: #007ad9;
  color: #ffffff;
  border: 1px solid #007ad9;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none; }
body .p-datagrid .p-datagrid-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .p-datagrid .p-datagrid-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none; }
body .p-datalist .p-datalist-header {
  background-color: #007ad9;
  color: #ffffff;
  border: 1px solid #007ad9;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none; }
body .p-datalist .p-datalist-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .p-datalist .p-datalist-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none; }
body .p-datascroller .p-datascroller-header {
  background-color: #007ad9;
  color: #ffffff;
  border: 1px solid #007ad9;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none; }
body .p-datascroller .p-datascroller-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .p-datascroller .p-datascroller-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none; }
body .p-dataview .p-dataview-header {
  background-color: #007ad9;
  color: #ffffff;
  border: 1px solid #007ad9;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
body .p-dataview .p-dataview-content {
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .p-dataview .p-dataview-footer {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  border-top: 0 none;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
body .fc th {
  background-color: #007ad9;
  border: 1px solid #007ad9;
  color: #ffffff;
  padding: 0.571em 1em; }
body .fc td.ui-widget-content {
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  color: #333333; }
body .fc td.fc-head-container {
  border: 1px solid #007ad9; }
body .fc .fc-row {
  border-right: 1px solid #007ad9; }
body .fc .fc-event {
  background-color: #116fbf;
  border: 1px solid #116fbf;
  color: #ffffff; }
body .fc .fc-toolbar .fc-button {
  color: #ffffff;
  background-color: #007ad9;
  border: 1px solid #007ad9;
  font-size: 14px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .fc .fc-toolbar .fc-button:enabled:hover {
    background-color: #116fbf;
    color: #ffffff;
    border-color: #116fbf; }
  body .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: 'PrimeIcons' !important;
    text-indent: 0; }
    body .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
      content: ""; }
  body .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: 'PrimeIcons' !important;
    text-indent: 0; }
    body .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
      content: ""; }
  body .fc .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
body .fc .fc-toolbar .fc-button-group .fc-button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0; }
  body .fc .fc-toolbar .fc-button-group .fc-button:first-child {
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  body .fc .fc-toolbar .fc-button-group .fc-button:last-child {
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px; }
body .fc .fc-divider {
  background-color: #007ad9;
  border: 1px solid #007ad9; }
body .p-fluid .fc .fc-toolbar .ui-button {
  width: auto; }
body .p-picklist .p-picklist-buttons button {
  font-size: 16px; }
body .p-picklist .p-picklist-caption {
  background-color: #007ad9;
  color: #ffffff;
  border: 1px solid #007ad9;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
body .p-picklist .p-picklist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #007ad9;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
  border-bottom: 0 none; }
  body .p-picklist .p-picklist-filter-container input {
    width: 100%;
    padding-right: 2em;
    text-indent: 0; }
  body .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    top: 50%;
    margin-top: -.5em;
    left: auto;
    right: 1.357em;
    color: #007ad9; }
body .p-picklist .p-picklist-buttons {
  padding: 0.571em 1em; }
body .p-picklist .p-picklist-list {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
  body .p-picklist .p-picklist-list .p-picklist-item {
    padding: 0.429em 0.857em;
    margin: 0;
    border: 0 none;
    color: #333333;
    background-color: transparent;
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s; }
    body .p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
      background-color: #eaeaea;
      color: #333333; }
    body .p-picklist .p-picklist-list .p-picklist-item:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
      box-shadow: inset 0 0 0 0.2em #8dcdff; }
    body .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
      background-color: #e02365;
      color: #ffffff; }
  body .p-picklist .p-picklist-list .p-picklist-droppoint-highlight {
    background-color: #e02365; }
@media (max-width: 40em) {
  body .p-picklist.p-picklist-responsive .p-picklist-buttons {
    padding: 0.571em 1em; } }
body .p-orderlist .p-orderlist-controls {
  padding: 0.571em 1em; }
  body .p-orderlist .p-orderlist-controls button {
    font-size: 16px; }
body .p-orderlist .p-orderlist-caption {
  background-color: #007ad9;
  color: #ffffff;
  border: 1px solid #007ad9;
  padding: 0.571em 1em;
  font-weight: 700;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
body .p-orderlist .p-orderlist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #007ad9;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
  border-bottom: 0 none; }
  body .p-orderlist .p-orderlist-filter-container input {
    width: 100%;
    padding-right: 2em;
    text-indent: 0; }
  body .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    top: 50%;
    margin-top: -.5em;
    left: auto;
    right: 1.357em;
    color: #007ad9; }
body .p-orderlist .p-orderlist-list {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
  body .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 0.429em 0.857em;
    margin: 0;
    border: 0 none;
    color: #333333;
    background-color: transparent;
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s; }
    body .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
      background-color: #eaeaea;
      color: #333333; }
    body .p-orderlist .p-orderlist-list .p-orderlist-item:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
      box-shadow: inset 0 0 0 0.2em #8dcdff; }
    body .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
      background-color: #e02365;
      color: #ffffff; }
  body .p-orderlist .p-orderlist-list .p-orderlist-droppoint-highlight {
    background-color: #e02365; }
body .p-tree {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-tree .p-tree-container {
    padding: 0.286em;
    margin: 0; }
    body .p-tree .p-tree-container .p-treenode {
      padding: 0.143em 0; }
      body .p-tree .p-tree-container .p-treenode .p-treenode-content {
        padding: 0;
        border: 1px solid transparent;
        -moz-transition: background-color 0.2s, box-shadow 0.2s;
        -o-transition: background-color 0.2s, box-shadow 0.2s;
        -webkit-transition: background-color 0.2s, box-shadow 0.2s;
        transition: background-color 0.2s, box-shadow 0.2s;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
          vertical-align: middle;
          display: inline-block;
          float: none;
          margin: 0 0.286em 0 0;
          color: #848484; }
          body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler b
          .p-tree-toggler-icon {
            line-height: 1.25em; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
          vertical-align: middle;
          display: inline-block;
          margin: 0 0.286em 0 0;
          color: #848484; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
          margin: 0;
          vertical-align: middle;
          display: inline-block;
          padding: 0.286em 0; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
          margin: 0 0.286em 0 0; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
          outline: 0 none;
          outline-offset: 0;
          -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
          -moz-box-shadow: 0 0 0 0.2em #8dcdff;
          box-shadow: 0 0 0 0.2em #8dcdff; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
          background-color: #e02365;
          color: #ffffff; }
          body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
          body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
            color: #ffffff; }
          body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-checkbox .p-checkbox-box {
            border-color: #ffffff; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight-contextmenu {
          border: 1px dashed #e02365; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):not(.p-disabled):hover {
          background-color: #eaeaea;
          color: #333333; }
          body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):not(.p-disabled):hover .p-treenode-icon {
            color: #333333; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content > span {
          line-height: inherit; }
        body .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
          background-color: #eaeaea;
          color: #333333; }
    body .p-tree .p-tree-container .p-treenode-droppoint.p-treenode-droppoint-active {
      background-color: #e02365; }
  body .p-tree.p-tree-horizontal {
    padding-left: 0;
    padding-right: 0; }
    body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
      border: 1px solid #c8c8c8;
      background-color: #ffffff;
      color: #333333;
      padding: 0.571em 1em;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
        background-color: #e02365;
        color: #ffffff; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox .p-icon {
        color: #007ad9; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):not(.p-disabled):hover {
        background-color: inherit;
        color: inherit; }
      body .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):not(.p-disabled):hover {
        background-color: #eaeaea;
        color: #333333; }
  body .p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 2em; }
  body .p-tree .p-tree-filter-container .p-tree-filter-icon {
    top: 50%;
    left: auto;
    margin-top: -.5em;
    right: .8em;
    color: #007ad9; }
body .p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background-color: #eaeaea;
  color: #333333; }
body .p-organizationchart .p-organizationchart-node-content.p-highlight {
  background-color: #e02365;
  color: #ffffff; }
  body .p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #731033; }
body .p-organizationchart .p-organizationchart-line-down {
  background-color: #c8c8c8; }
body .p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #c8c8c8;
  border-color: #c8c8c8; }
body .p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #c8c8c8;
  border-color: #c8c8c8; }
body .p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #c8c8c8;
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em; }
body .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  bottom: -.7em;
  margin-left: -.46em;
  color: #848484;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s; }
  body .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: 0 0 0 0.2em #8dcdff;
    box-shadow: 0 0 0 0.2em #8dcdff; }
body .p-treetable .p-treetable-header,
body .p-treetable .p-treetable-footer {
  background-color: #007ad9;
  color: #ffffff;
  border: 1px solid #007ad9;
  padding: 0.571em 1em;
  text-align: center; }
body .p-treetable .p-treetable-header {
  border-bottom: 0 none;
  font-weight: 700;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px; }
body .p-treetable .p-treetable-footer {
  border-top: 0 none;
  font-weight: 700;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px; }
body .p-treetable .p-treetable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4;
  text-align: center; }
body .p-treetable .p-treetable-tfoot > tr > td {
  padding: 0.571em 0.857em;
  border: 1px solid #c8c8c8;
  font-weight: 700;
  color: #333333;
  background-color: #f4f4f4;
  text-align: center; }
body .p-treetable .p-sortable-column {
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s; }
  body .p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #848484; }
  body .p-treetable .p-sortable-column:not(.p-highlight):hover {
    background-color: #e0e0e0;
    color: #333333; }
    body .p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: #333333; }
  body .p-treetable .p-sortable-column:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
    box-shadow: inset 0 0 0 0.2em #8dcdff; }
  body .p-treetable .p-sortable-column.p-highlight {
    background-color: #e02365;
    color: #ffffff; }
    body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: #ffffff; }
body .p-treetable .p-editable-column input {
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", sans-serif; }
body .p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #333333;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }
  body .p-treetable .p-treetable-tbody > tr > td {
    background: inherit;
    border: 1px solid #c8c8c8;
    padding: 0.429em 0.857em; }
    body .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
      color: #848484;
      vertical-align: middle; }
  body .p-treetable .p-treetable-tbody > tr.p-highlight {
    background-color: #e02365;
    color: #ffffff; }
    body .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
      color: #ffffff; }
  body .p-treetable .p-treetable-tbody > tr.p-highlight-contextmenu {
    background-color: #f093b3;
    color: #ffffff; }
    body .p-treetable .p-treetable-tbody > tr.p-highlight-contextmenu .p-treetable-toggler {
      color: #ffffff; }
body .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):not(.p-highlight-contextmenu):hover {
  cursor: pointer;
  background-color: #eaeaea;
  color: #333333; }
  body .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):not(.p-highlight-contextmenu):hover .p-treetable-toggler {
    color: #333333; }
body .p-treetable .p-column-resizer-helper {
  background-color: #007ad9; }
body .p-treetable .p-treetable-scrollable-header,
body .p-treetable .p-treetable-scrollable-footer {
  background-color: #007ad9; }
body .p-carousel .p-carousel-content .p-carousel-prev,
body .p-carousel .p-carousel-content .p-carousel-next {
  background-color: #ffffff;
  border: solid 1px rgba(178, 193, 205, 0.64);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0.2em;
  color: #333333;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s; }
  body .p-carousel .p-carousel-content .p-carousel-prev:not(.p-disabled):hover,
  body .p-carousel .p-carousel-content .p-carousel-next:not(.p-disabled):hover {
    background-color: #ffffff;
    color: #007ad9;
    border-color: solid 1px rgba(178, 193, 205, 0.64); }
body .p-carousel .p-carousel-dots-container .p-carousel-dot-item .p-carousel-dot-icon {
  width: 20px;
  height: 6px;
  background-color: #b2c1cd;
  margin: 0 0.2em; }
  body .p-carousel .p-carousel-dots-container .p-carousel-dot-item .p-carousel-dot-icon::before {
    content: ' '; }
body .p-carousel .p-carousel-dots-container .p-carousel-dot-item.p-highlight .p-carousel-dot-icon {
  background-color: #007ad9; }

body .p-messages {
  margin: 1em 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-messages .p-messages-wrapper {
    padding: 1em; }
  body .p-messages.p-messages-info {
    background-color: #7fbcec;
    border: 0 none;
    color: #212121; }
    body .p-messages.p-messages-info .p-messages-icon {
      color: #212121; }
    body .p-messages.p-messages-info .p-messages-close {
      background-color: #7fbcec;
      color: #212121; }
  body .p-messages.p-messages-success {
    background-color: #b7d8b7;
    border: 0 none;
    color: #212121; }
    body .p-messages.p-messages-success .p-messages-icon {
      color: #212121; }
    body .p-messages.p-messages-success .p-messages-close {
      background-color: #b7d8b7;
      color: #212121; }
  body .p-messages.p-messages-warn {
    background-color: #ffe399;
    border: 0 none;
    color: #212121; }
    body .p-messages.p-messages-warn .p-messages-icon {
      color: #212121; }
    body .p-messages.p-messages-warn .p-messages-close {
      background-color: #ffe399;
      color: #212121; }
  body .p-messages.p-messages-error {
    background-color: #f8b7bd;
    border: 0 none;
    color: #212121; }
    body .p-messages.p-messages-error .p-messages-icon {
      color: #212121; }
    body .p-messages.p-messages-error .p-messages-close {
      background-color: #f8b7bd;
      color: #212121; }
  body .p-messages .p-messages-close {
    top: -0.769em;
    right: -0.769em;
    width: 1.538em;
    height: 1.538em;
    line-height: 1.538em;
    text-align: center;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: box-shadow 0.2s;
    -o-transition: box-shadow 0.2s;
    -webkit-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s; }
    body .p-messages .p-messages-close .p-messages-close-icon {
      line-height: inherit;
      font-size: 1.5em; }
    body .p-messages .p-messages-close:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-messages .p-messages-icon {
    font-size: 2em; }
body .p-message {
  padding: 0.429em;
  margin: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-message.p-message-info {
    background-color: #7fbcec;
    border: 0 none;
    color: #212121; }
    body .p-message.p-message-info .p-message-icon {
      color: #212121; }
  body .p-message.p-message-success {
    background-color: #b7d8b7;
    border: 0 none;
    color: #212121; }
    body .p-message.p-message-success .p-message-icon {
      color: #212121; }
  body .p-message.p-message-warn {
    background-color: #ffe399;
    border: 0 none;
    color: #212121; }
    body .p-message.p-message-warn .p-message-icon {
      color: #212121; }
  body .p-message.p-message-error {
    background-color: #f8b7bd;
    border: 0 none;
    color: #212121; }
    body .p-message.p-message-error .p-message-icon {
      color: #212121; }
  body .p-message .p-message-icon {
    font-size: 1.25em; }
  body .p-message .p-message-text {
    font-size: 1em; }
body .p-growl {
  top: 70px;
  opacity: 0.9;
  filter: alpha(opacity=90); }
  body .p-growl .p-growl-item-container {
    margin: 0 0 1em 0;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .p-growl .p-growl-item-container .p-growl-item {
      padding: 1em; }
      body .p-growl .p-growl-item-container .p-growl-item .p-growl-message {
        margin: 0 0 0 4em; }
      body .p-growl .p-growl-item-container .p-growl-item .p-growl-image {
        font-size: 2.571em; }
    body .p-growl .p-growl-item-container.p-growl-message-info {
      background-color: #7fbcec;
      border: 0 none;
      color: #212121; }
      body .p-growl .p-growl-item-container.p-growl-message-info .p-growl-image,
      body .p-growl .p-growl-item-container.p-growl-message-info .p-growl-icon-close {
        color: #212121; }
    body .p-growl .p-growl-item-container.p-growl-message-success {
      background-color: #b7d8b7;
      border: 0 none;
      color: #212121; }
      body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-image,
      body .p-growl .p-growl-item-container.p-growl-message-success .p-growl-icon-close {
        color: #212121; }
    body .p-growl .p-growl-item-container.p-growl-message-warn {
      background-color: #ffe399;
      border: 0 none;
      color: #212121; }
      body .p-growl .p-growl-item-container.p-growl-message-warn .p-growl-image,
      body .p-growl .p-growl-item-container.p-growl-message-warn .p-growl-icon-close {
        color: #212121; }
    body .p-growl .p-growl-item-container.p-growl-message-error {
      background-color: #f8b7bd;
      border: 0 none;
      color: #212121; }
      body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-image,
      body .p-growl .p-growl-item-container.p-growl-message-error .p-growl-icon-close {
        color: #212121; }
body .p-toast {
  opacity: 0.9;
  filter: alpha(opacity=90); }
  body .p-toast .p-toast-item-container {
    margin: 0 0 1em 0;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
    body .p-toast .p-toast-item-container .p-toast-item {
      padding: 1em; }
      body .p-toast .p-toast-item-container .p-toast-item .p-toast-message {
        margin: 0 0 0 4em; }
      body .p-toast .p-toast-item-container .p-toast-item .p-toast-image {
        font-size: 2.571em; }
    body .p-toast .p-toast-item-container.p-toast-message-info {
      background-color: #7fbcec;
      border: 0 none;
      color: #212121; }
      body .p-toast .p-toast-item-container.p-toast-message-info .p-toast-image,
      body .p-toast .p-toast-item-container.p-toast-message-info .p-toast-icon-close {
        color: #212121; }
    body .p-toast .p-toast-item-container.p-toast-message-success {
      background-color: #b7d8b7;
      border: 0 none;
      color: #212121; }
      body .p-toast .p-toast-item-container.p-toast-message-success .p-toast-image,
      body .p-toast .p-toast-item-container.p-toast-message-success .p-toast-icon-close {
        color: #212121; }
    body .p-toast .p-toast-item-container.p-toast-message-warn {
      background-color: #ffe399;
      border: 0 none;
      color: #212121; }
      body .p-toast .p-toast-item-container.p-toast-message-warn .p-toast-image,
      body .p-toast .p-toast-item-container.p-toast-message-warn .p-toast-icon-close {
        color: #212121; }
    body .p-toast .p-toast-item-container.p-toast-message-error {
      background-color: #f8b7bd;
      border: 0 none;
      color: #212121; }
      body .p-toast .p-toast-item-container.p-toast-message-error .p-toast-image,
      body .p-toast .p-toast-item-container.p-toast-message-error .p-toast-icon-close {
        color: #212121; }

body .p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4); }
body .p-overlaypanel {
  background-color: #ffffff;
  color: #333333;
  padding: 0;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); }
  body .p-overlaypanel .p-overlaypanel-content {
    padding: 1em; }
  body .p-overlaypanel .p-overlaypanel-close {
    background-color: #007ad9;
    color: #ffffff;
    width: 1.538em;
    height: 1.538em;
    line-height: 1.538em;
    text-align: center;
    position: absolute;
    top: -0.769em;
    right: -0.769em;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s; }
    body .p-overlaypanel .p-overlaypanel-close:hover {
      background-color: #005b9f;
      color: #ffffff; }
    body .p-overlaypanel .p-overlaypanel-close > span {
      line-height: inherit; }
  body .p-overlaypanel:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #ffffff; }
  body .p-overlaypanel:before {
    border-color: rgba(200, 200, 200, 0);
    border-bottom-color: #c8c8c8; }
  body .p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #ffffff; }
  body .p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #c8c8c8; }
body .p-dialog {
  padding: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); }
  body .p-dialog .p-dialog-titlebar {
    border: 1px solid #007ad9;
    background-color: #007ad9;
    color: #ffffff;
    padding: 1em;
    font-weight: 700;
    border-bottom: 0 none;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px; }
    body .p-dialog .p-dialog-titlebar .p-dialog-title {
      margin: 0;
      float: none;
      font-weight: 700; }
    body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon {
      color: #ffffff;
      border: 0 none;
      padding: 0;
      margin-left: 0.5em;
      -moz-transition: color 0.2s, box-shadow 0.2s;
      -o-transition: color 0.2s, box-shadow 0.2s;
      -webkit-transition: color 0.2s, box-shadow 0.2s;
      transition: color 0.2s, box-shadow 0.2s; }
      body .p-dialog .p-dialog-titlebar .p-dialog-titlebar-icon:hover {
        color: #5ab7ff; }
  body .p-dialog .p-dialog-content {
    background-color: #ffffff;
    color: #333333;
    border: 1px solid #c8c8c8;
    padding: 1em; }
  body .p-dialog .p-dialog-footer {
    border: 1px solid #c8c8c8;
    border-top: 0 none;
    background-color: #ffffff;
    color: #333333;
    padding: 0.571em 1em;
    margin: 0;
    text-align: right; }
    body .p-dialog .p-dialog-footer button {
      margin: 0 0.5em 0 0;
      width: auto; }
  body .p-dialog.p-confirm-dialog .p-dialog-content {
    padding: 1.5em; }
    body .p-dialog.p-confirm-dialog .p-dialog-content > span {
      float: none;
      display: inline-block;
      vertical-align: middle;
      line-height: 14px;
      margin: 0; }
      body .p-dialog.p-confirm-dialog .p-dialog-content > span.p-icon {
        margin-right: .35em;
        font-size: 16px; }
body .p-sidebar {
  background-color: #ffffff;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); }
  body .p-sidebar .p-sidebar-close {
    color: #848484; }
    body .p-sidebar .p-sidebar-close:hover {
      color: #333333; }
body .p-tooltip .p-tooltip-text {
  background-color: #333333;
  color: #ffffff;
  padding: 0.429em;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
body .p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #333333; }
body .p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #333333; }
body .p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #333333; }
body .p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #333333; }
body .p-lightbox {
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16); }
  body .p-lightbox .p-lightbox-caption {
    border: 1px solid #007ad9;
    background-color: #007ad9;
    color: #ffffff;
    padding: 0.571em 1em;
    font-weight: 700; }
    body .p-lightbox .p-lightbox-caption .p-lightbox-caption-text {
      color: #ffffff;
      margin: 0; }
    body .p-lightbox .p-lightbox-caption .p-lightbox-close {
      padding: 0;
      color: #ffffff;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
      -webkit-transition: color 0.2s;
      transition: color 0.2s; }
      body .p-lightbox .p-lightbox-caption .p-lightbox-close:hover {
        color: #5ab7ff; }
  body .p-lightbox .p-lightbox-content-wrapper {
    overflow: hidden;
    background-color: #ffffff;
    color: #333333;
    border: 0 none;
    padding: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0; }
    body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right {
      -moz-transition: all 0.2s;
      -o-transition: all 0.2s;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      font-size: 3em;
      color: #ffffff;
      margin-top: -.5em; }
      body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-left:hover, body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-nav-right:hover {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -o-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2); }
    body .p-lightbox .p-lightbox-content-wrapper .p-lightbox-content.p-lightbox-loading ~ a {
      display: none; }

body .p-breadcrumb {
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-breadcrumb ul li .p-menuitem-link {
    color: #333333;
    margin: 0;
    -moz-transition: box-shadow 0.2s;
    -o-transition: box-shadow 0.2s;
    -webkit-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; }
    body .p-breadcrumb ul li .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff; }
  body .p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 0.5em 0 0.5em;
    color: #848484; }
  body .p-breadcrumb ul li:first-child a {
    color: #848484;
    margin: 0; }
  body .p-breadcrumb ul li .p-menuitem-icon {
    color: #848484; }
body .p-steps {
  position: relative; }
  body .p-steps .p-steps-item {
    background-color: transparent;
    text-align: center; }
    body .p-steps .p-steps-item .p-menuitem-link {
      display: inline-block;
      text-align: center;
      background-color: transparent;
      overflow: hidden;
      -moz-transition: box-shadow 0.2s;
      -o-transition: box-shadow 0.2s;
      -webkit-transition: box-shadow 0.2s;
      transition: box-shadow 0.2s;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px; }
      body .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        display: inline-block;
        color: #333333;
        background-color: #ffffff;
        border: 1px solid #c8c8c8;
        position: relative;
        top: 16px;
        margin-bottom: 14px;
        width: 28px;
        height: 28px;
        font-size: 16px;
        line-height: 24px;
        text-align: center; }
      body .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
        display: block;
        margin-top: 6px;
        color: #848484; }
      body .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff; }
    body .p-steps .p-steps-item.p-highlight .p-steps-number {
      background: #e02365;
      color: #ffffff; }
    body .p-steps .p-steps-item.p-highlight .p-steps-title {
      font-weight: 700;
      color: #333333; }
    body .p-steps .p-steps-item:last-child .p-menuitem-link {
      display: block; }
  body .p-steps:before {
    content: ' ';
    border-top: 1px solid #c8c8c8;
    width: 100%;
    top: 45%;
    left: 0;
    display: block;
    position: absolute; }
body .p-menu .p-menuitem-link,
body .p-menubar .p-menuitem-link,
body .p-tieredmenu .p-menuitem-link,
body .p-contextmenu .p-menuitem-link,
body .p-megamenu .p-menuitem-link,
body .p-slidemenu .p-menuitem-link {
  padding: 0.714em 0.857em;
  color: #333333;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s; }
  body .p-menu .p-menuitem-link .p-menuitem-text,
  body .p-menubar .p-menuitem-link .p-menuitem-text,
  body .p-tieredmenu .p-menuitem-link .p-menuitem-text,
  body .p-contextmenu .p-menuitem-link .p-menuitem-text,
  body .p-megamenu .p-menuitem-link .p-menuitem-text,
  body .p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #333333; }
  body .p-menu .p-menuitem-link .p-menuitem-icon,
  body .p-menubar .p-menuitem-link .p-menuitem-icon,
  body .p-tieredmenu .p-menuitem-link .p-menuitem-icon,
  body .p-contextmenu .p-menuitem-link .p-menuitem-icon,
  body .p-megamenu .p-menuitem-link .p-menuitem-icon,
  body .p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #333333;
    margin-right: 0.5em; }
  body .p-menu .p-menuitem-link:hover,
  body .p-menubar .p-menuitem-link:hover,
  body .p-tieredmenu .p-menuitem-link:hover,
  body .p-contextmenu .p-menuitem-link:hover,
  body .p-megamenu .p-menuitem-link:hover,
  body .p-slidemenu .p-menuitem-link:hover {
    background-color: #eaeaea; }
    body .p-menu .p-menuitem-link:hover .p-menuitem-text,
    body .p-menubar .p-menuitem-link:hover .p-menuitem-text,
    body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-text,
    body .p-contextmenu .p-menuitem-link:hover .p-menuitem-text,
    body .p-megamenu .p-menuitem-link:hover .p-menuitem-text,
    body .p-slidemenu .p-menuitem-link:hover .p-menuitem-text {
      color: #333333; }
    body .p-menu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-menubar .p-menuitem-link:hover .p-menuitem-icon,
    body .p-tieredmenu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-contextmenu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-megamenu .p-menuitem-link:hover .p-menuitem-icon,
    body .p-slidemenu .p-menuitem-link:hover .p-menuitem-icon {
      color: #333333; }
  body .p-menu .p-menuitem-link:focus,
  body .p-menubar .p-menuitem-link:focus,
  body .p-tieredmenu .p-menuitem-link:focus,
  body .p-contextmenu .p-menuitem-link:focus,
  body .p-megamenu .p-menuitem-link:focus,
  body .p-slidemenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
    box-shadow: inset 0 0 0 0.2em #8dcdff; }
body .p-menu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-menu .p-menuitem {
    margin: 0; }
  body .p-menu.p-menu-overlay {
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
  body .p-menu .p-submenu-header {
    margin: 0;
    padding: 0.714em 0.857em;
    color: #ffffff;
    background-color: #007ad9;
    font-weight: 700;
    border: 0 none;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px; }
  body .p-menu .p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0; }
body .p-menubar {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.714em 0.857em; }
  body .p-menubar .p-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
    body .p-menubar .p-submenu-list .p-menuitem {
      margin: 0; }
    body .p-menubar .p-submenu-list .p-menu-separator {
      border: 1px solid #c8c8c8;
      border-width: 1px 0 0 0; }
  body .p-menubar .p-menuitem {
    margin: 0; }
    body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #eaeaea; }
      body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #333333; }
      body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
        color: #333333; }
body .p-contextmenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-contextmenu .p-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
  body .p-contextmenu .p-menuitem {
    margin: 0; }
    body .p-contextmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #eaeaea; }
      body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #333333; }
      body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
        color: #333333; }
  body .p-contextmenu .p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0; }
body .p-tieredmenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-tieredmenu .p-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
  body .p-tieredmenu .p-menuitem {
    margin: 0; }
    body .p-tieredmenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #eaeaea; }
      body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #333333; }
      body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
        color: #333333; }
  body .p-tieredmenu .p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0; }
  body .p-tieredmenu.p-menu-overlay {
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
body .p-slidemenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-slidemenu .p-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 0 none; }
  body .p-slidemenu .p-menuitem {
    margin: 0; }
    body .p-slidemenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #eaeaea; }
      body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #333333; }
      body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-slidemenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
        color: #333333; }
  body .p-slidemenu .p-slidemenu-backward {
    margin: 0;
    padding: 0.571em 1em;
    color: #ffffff;
    background-color: #007ad9;
    font-weight: 700;
    border: 0 none; }
    body .p-slidemenu .p-slidemenu-backward:hover {
      background-color: #27a0ff;
      color: #ffffff; }
  body .p-slidemenu .p-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0; }
  body .p-slidemenu.p-menu-overlay {
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
body .p-tabmenu {
  border: 0 none; }
  body .p-tabmenu .p-tabmenu-nav {
    padding: 0;
    background: transparent;
    border-bottom: 1px solid #007ad9; }
    body .p-tabmenu .p-tabmenu-nav:after {
      content: "";
      display: table;
      clear: both; }
    body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
      margin-bottom: -1px;
      border: 1px solid #007ad9;
      background-color: #007ad9;
      color: #ffffff;
      padding: 0.571em 1em;
      margin-right: 2px;
      -moz-border-radius-topleft: 3px;
      -webkit-border-top-left-radius: 3px;
      border-top-left-radius: 3px;
      -moz-border-radius-topright: 3px;
      -webkit-border-top-right-radius: 3px;
      border-top-right-radius: 3px;
      -moz-transition: background-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, box-shadow 0.2s; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-text {
        color: #ffffff; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
        color: #ffffff;
        margin-right: 0.5em; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: 0 0 0 0.2em #8dcdff;
        box-shadow: 0 0 0 0.2em #8dcdff; }
    body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
      background-color: #27a0ff;
      border: 1px solid #27a0ff; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-text {
        color: #ffffff; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link .p-menuitem-icon {
        color: #5ab7ff; }
    body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
      background-color: #e02365;
      border: 1px solid #e02365; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-text {
        color: #ffffff; }
      body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-icon {
        color: #ffffff; }
body .p-megamenu {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }
  body .p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: 0.571em 1em;
    color: #ffffff;
    background-color: #007ad9;
    font-weight: 700;
    border: 0 none; }
  body .p-megamenu .p-megamenu-panel {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16); }
  body .p-megamenu .p-menuitem {
    margin: 0; }
    body .p-megamenu .p-menuitem .p-menuitem-link .p-submenu-icon {
      right: 0.429em; }
    body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
      background-color: #eaeaea; }
      body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
        color: #333333; }
      body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, body .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
        color: #333333; }
  body .p-megamenu.p-megamenu-vertical .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    right: 0.429em; }
body .p-panelmenu .p-icon {
  position: static; }
body .p-panelmenu .p-panelmenu-panel .p-panelmenu-header {
  margin-top: 2px; }
body .p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header {
  margin-top: 0; }
body .p-panelmenu .p-panelmenu-header {
  padding: 0; }
  body .p-panelmenu .p-panelmenu-header > a {
    border: 1px solid #007ad9;
    background-color: #007ad9;
    color: #ffffff;
    padding: 0.714em 0.857em;
    font-weight: 700;
    position: static;
    font-size: 14px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: background-color 0.2s, box-shadow 0.2s;
    -o-transition: background-color 0.2s, box-shadow 0.2s;
    -webkit-transition: background-color 0.2s, box-shadow 0.2s;
    transition: background-color 0.2s, box-shadow 0.2s; }
    body .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
      color: #ffffff; }
      body .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon.pi-caret-right:before {
        content: ""; }
      body .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon.pi-caret-down:before {
        content: ""; }
    body .p-panelmenu .p-panelmenu-header > a:focus {
      outline: 0 none;
      outline-offset: 0;
      -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
      -moz-box-shadow: 0 0 0 0.2em #8dcdff;
      box-shadow: 0 0 0 0.2em #8dcdff;
      z-index: 100; }
  body .p-panelmenu .p-panelmenu-header:not(.p-highlight) > a:hover {
    outline: 0 none;
    border: 1px solid #27a0ff;
    background-color: #27a0ff;
    color: #ffffff; }
    body .p-panelmenu .p-panelmenu-header:not(.p-highlight) > a:hover .p-panelmenu-icon {
      color: #ffffff; }
  body .p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0; }
    body .p-panelmenu .p-panelmenu-header.p-highlight > a {
      border: 1px solid #e02365;
      background-color: #e02365;
      color: #ffffff;
      -moz-border-radius-bottomleft: 0;
      -webkit-border-bottom-left-radius: 0;
      border-bottom-left-radius: 0;
      -moz-border-radius-bottomright: 0;
      -webkit-border-bottom-right-radius: 0;
      border-bottom-right-radius: 0; }
      body .p-panelmenu .p-panelmenu-header.p-highlight > a .p-panelmenu-icon {
        color: #ffffff; }
      body .p-panelmenu .p-panelmenu-header.p-highlight > a:hover {
        outline: 0 none;
        border: 1px solid #e96694;
        background-color: #e96694;
        color: #ffffff; }
        body .p-panelmenu .p-panelmenu-header.p-highlight > a:hover .p-panelmenu-icon {
          color: #ffffff; }
body .p-panelmenu .p-panelmenu-panel:first-child {
  margin-top: 0; }
body .p-panelmenu .p-panelmenu-content {
  padding: 0;
  background-color: #ffffff;
  border: 1px solid #c8c8c8;
  margin-top: 0;
  position: static;
  border-top: 0 none; }
  body .p-panelmenu .p-panelmenu-content .p-menuitem {
    margin: 0; }
    body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
      padding: 0.714em 0.857em;
      color: #333333;
      -moz-transition: background-color 0.2s, box-shadow 0.2s;
      -o-transition: background-color 0.2s, box-shadow 0.2s;
      -webkit-transition: background-color 0.2s, box-shadow 0.2s;
      transition: background-color 0.2s, box-shadow 0.2s; }
      body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover {
        background-color: #eaeaea; }
        body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-menuitem-icon, body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-panelmenu-icon {
          color: #333333; }
        body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover .p-menuitem-text {
          color: #333333; }
      body .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
        -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
        box-shadow: inset 0 0 0 0.2em #8dcdff; }

body .p-progressbar {
  border: 0 none;
  height: 24px;
  background-color: #eaeaea; }
  body .p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #007ad9; }
  body .p-progressbar .p-progressbar-label {
    margin-top: 0;
    color: #333333;
    line-height: 24px; }
body .p-galleria .p-galleria-nav-prev,
body .p-galleria .p-galleria-nav-next {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  color: #848484; }
  body .p-galleria .p-galleria-nav-prev:hover,
  body .p-galleria .p-galleria-nav-next:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }
body .p-galleria .p-galleria-nav-prev {
  font-size: 16px; }
body .p-galleria .p-galleria-nav-next {
  font-size: 16px; }
body .p-terminal {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #c8c8c8;
  padding: 0.571em 1em; }
  body .p-terminal .p-terminal-input {
    font-size: 14px;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    height: 16px; }
  body .p-terminal .p-terminal-command {
    height: 16px; }
body .p-inplace {
  min-height: 2.357em; }
  body .p-inplace .p-inplace-display {
    padding: 0.429em;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: background-color 0.2s;
    -o-transition: background-color 0.2s;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s; }
    body .p-inplace .p-inplace-display:not(.p-disabled):hover {
      background-color: #eaeaea;
      color: #333333; }
body .p-fluid .p-inplace.p-inplace-closable .p-inplace-content > :first-child {
  display: inline-block;
  width: calc(100% - 2.357em); }